// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hstack{
  /*  fix fonts */
  display: flex;
  background-image: linear-gradient(to  bottom right, rgb(0, 0, 0), rgb(45, 44, 44));
  
  height: 100vh;

}

.elm{

  display: flex;
  align-items: center;

  margin: 0%;
  width: 50%;
  height: 100vh;
}

.vstack{
  /* background-color: blueviolet; */
  display: flex;
  /* padding: 1%; */
  flex-direction: column;
  justify-content: space-evenly;

  margin: 2%;
  width: 46%;
  height: 96vh;

}

.heading{
  font-size: 400%;
  color: #a2a2a2;
}

.subheading{
  font-size: 170%;
  color:#a2a2a2;
}

.button{

  color: black;
  background-image:linear-gradient(to right, rgb(5, 236, 55) , rgb(29, 160, 57));
  width: 75%;
  height: 7%;
  align-self: center;
  
}

.stingrays{
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: 30% 30% 30%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,kFAAkF;;EAElF,aAAa;;AAEf;;AAEA;;EAEE,aAAa;EACb,mBAAmB;;EAEnB,UAAU;EACV,UAAU;EACV,aAAa;AACf;;AAEA;EACE,kCAAkC;EAClC,aAAa;EACb,iBAAiB;EACjB,sBAAsB;EACtB,6BAA6B;;EAE7B,UAAU;EACV,UAAU;EACV,YAAY;;AAEd;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;;EAEE,YAAY;EACZ,8EAA8E;EAC9E,UAAU;EACV,UAAU;EACV,kBAAkB;;AAEpB;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,+BAA+B;AACjC","sourcesContent":[".hstack{\n  /*  fix fonts */\n  display: flex;\n  background-image: linear-gradient(to  bottom right, rgb(0, 0, 0), rgb(45, 44, 44));\n  \n  height: 100vh;\n\n}\n\n.elm{\n\n  display: flex;\n  align-items: center;\n\n  margin: 0%;\n  width: 50%;\n  height: 100vh;\n}\n\n.vstack{\n  /* background-color: blueviolet; */\n  display: flex;\n  /* padding: 1%; */\n  flex-direction: column;\n  justify-content: space-evenly;\n\n  margin: 2%;\n  width: 46%;\n  height: 96vh;\n\n}\n\n.heading{\n  font-size: 400%;\n  color: #a2a2a2;\n}\n\n.subheading{\n  font-size: 170%;\n  color:#a2a2a2;\n}\n\n.button{\n\n  color: black;\n  background-image:linear-gradient(to right, rgb(5, 236, 55) , rgb(29, 160, 57));\n  width: 75%;\n  height: 7%;\n  align-self: center;\n  \n}\n\n.stingrays{\n  display: grid;\n  grid-template-columns: 30% 30% 30%;\n  grid-template-rows: 30% 30% 30%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
